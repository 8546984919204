var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mega-loading" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowSpinner,
            expression: "isShowSpinner"
          }
        ],
        staticClass: "mega-status-prompt",
        style: _vm.slotStyles.spinner
      },
      [
        _vm._t("spinner", function() {
          return [
            _c("spinner", {
              staticClass: "mega-loading-spinner",
              attrs: { spinner: _vm.spinner }
            })
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowNoResults,
            expression: "isShowNoResults"
          }
        ],
        staticClass: "mega-status-prompt",
        style: _vm.slotStyles.noResults
      },
      [
        _vm._t("no-results", function() {
          return [
            _vm.slots.noResults.render
              ? _c(_vm.slots.noResults, { tag: "component" })
              : [_vm._v(_vm._s(_vm.slots.noResults))]
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowNoMore,
            expression: "isShowNoMore"
          }
        ],
        staticClass: "mega-status-prompt",
        style: _vm.slotStyles.noMore
      },
      [
        _vm._t("no-more", function() {
          return [
            _vm.slots.noMore.render
              ? _c(_vm.slots.noMore, { tag: "component" })
              : [_vm._v(_vm._s(_vm.slots.noMore))]
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowError,
            expression: "isShowError"
          }
        ],
        staticClass: "mega-status-prompt",
        style: _vm.slotStyles.error
      },
      [
        _vm._t(
          "error",
          function() {
            return [
              _vm.slots.error.render
                ? _c(_vm.slots.error, {
                    tag: "component",
                    attrs: { trigger: _vm.attemptLoad }
                  })
                : [
                    _vm._v(
                      "\n        " + _vm._s(_vm.slots.error) + "\n        "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("button", {
                      staticClass: "btn-try-infinite",
                      domProps: { textContent: _vm._s(_vm.slots.errorBtnText) },
                      on: { click: _vm.attemptLoad }
                    })
                  ]
            ]
          },
          { trigger: _vm.attemptLoad }
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }