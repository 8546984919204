var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "-2000 -1000 4000 2000" } }, [
    _c("path", {
      attrs: {
        id: "inf",
        d: "M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"
      }
    }),
    _vm._v(" "),
    _c("use", {
      attrs: {
        "xlink:href": "#inf",
        "stroke-dasharray": "1570 5143",
        "stroke-dashoffset": "6713px"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }