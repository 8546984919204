import config from "./config";
import InfiniteLoading from "./components/loading.vue";

function syncModeFromVue(Vue) {
  config.mode = Vue.config.productionTip ? "development" : "production";
}

Object.defineProperty(InfiniteLoading, "install", {
  configurable: false,
  enumerable: false,
  value(Vue, options) {
    Object.assign(config.props, options && options.props);
    Object.assign(config.slots, options && options.slots);
    Object.assign(config.system, options && options.system);
    Vue.component("mega-loading", InfiniteLoading);

    syncModeFromVue(Vue);
  }
});

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.component("mega-loading", InfiniteLoading);
  syncModeFromVue(window.Vue);
}

export default InfiniteLoading;
